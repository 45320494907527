import React from 'react'
import Seo from '../components/seo'

const NotFound = () => {
  return (
    <main id="innehall" aria-labelledby="main-heading">
      <Seo title="Sidan kunde inte hittas - Koll på cashen" locale="sv-SE" />

      <div className="max-w-screen-xl mx-auto px-6 pt-8 md:px-6 md:pt-32 pb-64">
        <div className="grid lg:grid-cols-3 lg:gap-12">
          <div className="md:col-span-2">
            <h1
              id="main-heading"
              className="text-4xl leading-none  md:text-6xl mb-6 md:mb-8 font-bold"
            >
              404 - Sidan kunde inte hittas
            </h1>
          </div>
          <div className="md:col-span-1 relative"></div>
        </div>
      </div>
    </main>
  )
}

export default NotFound
